import Vue from 'vue'
import App from './App.vue'

import router from './router'
import message from './validate/message'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'font-awesome/css/font-awesome.css';

import { BootstrapVue } from 'bootstrap-vue'
import axios from 'axios'
import VeeValidate from 'vee-validate'
import money from 'v-money'

Vue.use(BootstrapVue)
Vue.use(money, {precision: 4})

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors',
  locale: 'pt_BR',
  dictionary: {
    pt_BR:{
      messages: message
    }
  }
})

Vue.prototype.$http = axios
//Vue.config.productionTip = false

new Vue({
  el: '#app',
  render: h => h(App),
  router
}).$mount('#app')
