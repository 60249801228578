import Vue from 'vue';
import VueRouter from 'vue-router';

import FormPage from '../pages/FormPage'
import QuestionPage from '../pages/QuestionPage'

Vue.use(VueRouter);

const routes = [
    { name: 'Form', path: '/form', component: FormPage },
    { name: 'Question', path: '/question', component: QuestionPage },
];

const router = new VueRouter({
    routes: routes
});

export default router