import { sgbUrl } from '@/config/global'
//import axios from 'axios'

const graphQLQuery = (_http, query, token, variables = null) => {
  let graphQLQueryObject = {
    query,
    variables
  }
  let config = {}
  config.headers = {}
  config.headers['Authorization'] = `Bearer ${token}`

  return _http.post(`${sgbUrl}/graphql`, graphQLQueryObject, config)
    .then(res => res)
    .catch(error => {
      console.log(error.response)
    })
}

export { graphQLQuery }