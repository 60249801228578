<template>
    <div class="text-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Carregando...</span>
        </div>
        <p>Carregando...</p>
    </div>
</template>

<script>
export default {
    data: function(){
        return {
        }
    }
}
</script>

<style>
</style>