import { sgbUrl } from '@/config/global'

export default class FormService {
    constructor (http) {
        this._http = http;
    }

    get (token, user) {
      return this._http
        .get(`${sgbUrl}/pi/approve?token=${token}&user=${user}`);
    }

    action (token, user, approve, reason) {
      let reasonVar = ''
      if (reason !== undefined) {
        reasonVar = `&note=${reason}`
      }
      console.log(`${sgbUrl}/pi/approve?token=${token}&user=${user}&approve${approve}${reasonVar}`)
      return this._http
        .post(`${sgbUrl}/pi/approve?token=${token}&user=${user}&approve=${approve}${reasonVar}`);
    }

    download(id, token) {
      return fetch(`${sgbUrl}/File/Download/InsertionOrder/${id}`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${token}`
        }
        }).then(
          response => response.blob()
        )
    }
}