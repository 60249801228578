<template>
  <div class="formPage-body">
    <template v-if="params">
      <b-overlay :show="spinner" rounded="sm" variant="transparent">
        <b-row>
          <b-col cols="12">
            <b-table 
              striped hover
              :fields="fields"
              :items="items"
              foot-clone
              style="text-align:center"
            >
              <template v-slot:head()="row">
                <div v-if="row.column == 'action'" style="width: 90px;">
                  <b-row>
                    <b-col cols="12">                      
                    </b-col>
                    <b-col cols="10">                      
                    </b-col>
                  </b-row> 
                </div>
                <div v-else-if="row.column == 'period'">  
                  <b-row style="text-align: -webkit-center;">
                    <b-col cols="12">
                      {{ row.label }}                      
                    <b-button v-b-modal.period  class="sm ml-2" id="button-1" pill variant="light"><i class="fa fa-question"></i></b-button>
                    <b-tooltip target="button-1" placement="bottom">
                      <strong>Esse campo deve ser preenchido com o período da campanha</strong>
                    </b-tooltip>
                  </b-col>
                    <b-col cols="10">
                      <p style="font-size:x-small" >Obrigatório</p>
                    </b-col>
                  </b-row> 
                </div>
                <div v-else-if="row.column == 'totalCost'">
                  <b-row style="text-align: -webkit-center;">
                    <b-col cols="12">
                      {{ row.label }}
                      <b-button v-b-modal.totalCost  class="sm ml-2" id="button-2" pill variant="light"><i class="fa fa-question"></i></b-button>
                        <b-tooltip target="button-2" placement="bottom">
                          <strong>Preencher esse campo com o valor bruto negociado com o veículo</strong>
                        </b-tooltip>
                    </b-col>
                    <b-col cols="10">
                      <p style="font-size:x-small" >Obrigatório</p>
                    </b-col>
                  </b-row>
                </div>
                <div v-else-if="row.column == 'campaignName'">
                  <b-row style="text-align: -webkit-center;">
                    <b-col cols="12">
                      {{ row.label }}
                      <b-button v-b-modal.campaignName  class="sm ml-2" id="button-3" pill variant="light"><i class="fa fa-exclamation"></i></b-button>
                      <b-tooltip target="button-3" placement="bottom">
                        <strong>Identificar a campanha.</strong>
                      </b-tooltip>                    
                    </b-col>
                    <b-col cols="10">
                      <p style="font-size:xx-small" >Opcional</p>
                    </b-col>
                  </b-row>          
                </div>
                <div v-else-if="row.column == 'negociationId'">
                  <b-row style="text-align: -webkit-center;">
                    <b-col cols="12">
                  {{ row.label }}
                  <b-button v-b-modal.negociationId  class="sm ml-2" id="button-4" pill variant="light"><i class="fa fa-exclamation"></i></b-button>
                  <b-tooltip target="button-4" placement="bottom">
                    <strong>Identificar o modelo de compra utilizado.</strong>
                  </b-tooltip>
                </b-col>
                    <b-col cols="10">
                      <p style="font-size:xx-small" >Opcional</p>
                    </b-col>
                  </b-row>
                </div>
                <div v-else-if="row.column == 'contractVolume'">
                  <b-row style="text-align: -webkit-center;">
                    <b-col cols="12">
                  {{ row.label }}
                  <b-button v-b-modal.contractVolume  class="sm ml-2" id="button-5" pill variant="light"><i class="fa fa-exclamation"></i></b-button>
                  <b-tooltip target="button-5" placement="bottom">
                    <strong>Identificar o volume de impressões e/ou views contratados</strong>
                  </b-tooltip>
                </b-col>
                    <b-col cols="10">
                      <p style="font-size:xx-small" >Opcional</p>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  {{ row.label }}
                </div>
              </template>
              <template v-slot:cell(campaignName)="row">
                <div v-if="row.index != items.length - 1 && rowEdit != row.index">
                  {{ row.item.campaignName }}
                </div>
                <b-form-input v-else
                  id="input-1"
                  name="campaignName"
                  v-model="items[row.index].campaignName"
                  type="text"
                  placeholder="Digite a campanha"
                  class="w-100"
                  style="display:inline !important; text-align: center;">
                </b-form-input>
                <b-tooltip target="input-1" placement="bottom">
                    <strong>Opcional</strong>
                </b-tooltip>
              </template>
              <template v-slot:cell(negociationId)="row">
                <div v-if="row.index != items.length - 1 && rowEdit != row.index">
                  {{ negociationIdList.find(x => x.value === row.item.negociationId) != null ? negociationIdList.find(x => x.value === row.item.negociationId).text : '' }}
                </div>
                <b-form-select v-else
                  name="negociationId"
                  v-model="items[row.index].negociationId" 
                  :options="negociationIdList"
                  class="w-75"
                  style="display:inline !important; text-align: center;"
                ></b-form-select>
              </template>
              <template v-slot:cell(contractVolume)="row">
                <div v-if="row.index != items.length - 1 && rowEdit != row.index">
                  {{ row.item.contractVolume }}
                </div>
                <b-form-input v-else
                  name="contractVolume"
                  v-model="items[row.index].contractVolume"
                  type="number"
                  placeholder="Digite o Volume"
                  min="0"
                  class="w-25"
                  style="display:inline !important; text-align: center;"
                ></b-form-input>
              </template>
              <template v-slot:cell(period)="row">
                <div v-if="row.index != items.length - 1 && rowEdit != row.index">
                  {{ formatPeriod(row.item.period) }}
                </div>
                <date-range-picker v-else v-model="row.item.period" :minDate="minDate" :maxDate="maxDate" :locale-data="{ firstDay: 0, format: 'DD/MM/YYYY' }" singleDatePicker="range">
                  <template #input="picker" style="min-width: 350px;">
                    {{ formatDate(picker.startDate) }} - {{ formatDate(picker.endDate) }}
                  </template>
                  <template #ranges="ranges">
                    <div class="ranges">
                      <ul>
                        <li v-for="(range, name) in formatRangeArray(ranges.ranges)" :key="name" @click="ranges.clickRange(range)">
                          <b>{{formatRangeName(name)}}</b> <small class="text-muted">{{range[0].toDateString()}} - {{range[1].toDateString()}}</small>
                        </li>
                      </ul>
                    </div>
                  </template>
                  <div slot="footer" slot-scope="data" class="slot">
                    <div class="mx-auto text-center mt-2 mb-4">
                      <a @click="saveRange(data)" v-if="!data.in_selection" class="btn btn-primary btn-sm">Confirmar Escolha</a>
                    </div>
                  </div>
                </date-range-picker>
              </template>
              <template v-slot:cell(unitCost)="row">
                <div>
                  {{ formatUnitCost(row.item, row.item.negociationId) }}
                </div>
              </template>
              <template v-slot:cell(totalCost)="row">
                <div v-if="row.index != items.length - 1 && rowEdit != row.index">
                  {{ new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(row.item.totalCost) }}
                </div>
                <money v-else 
                  name="totalCost"
                  type="text"
                  placeholder="Digite o total"
                  v-model="items[row.index].totalCost"
                  v-bind="money"
                  class="form-control w-75"
                  style="display:inline !important; text-align: center;"
                ></money>
              </template>
              <template v-slot:cell(action)="row">
                <b-button @click="addItem()" v-b-tooltip.hover title="Salvar" v-if="row.index == items.length - 1" pill variant="info" :disabled="isItemAddDisabled(row.index)">
                  <i class="fa fa-save"></i>
                </b-button>
                <b-button @click="editItem(row.index)" v-b-tooltip.hover title="Editar" v-if="row.index != items.length - 1 && rowEdit != row.index" pill variant="info">
                  <i class="fa fa-pencil"></i>
                </b-button>
                <b-button @click="saveItem()" v-b-tooltip.hover title="Salvar" v-if="rowEdit == row.index" pill variant="info" :disabled="isItemEditDisabled(row.index)">
                  <i class="fa fa-check"></i>
                </b-button>
                <b-button @click="removeItem(row.index)" v-b-tooltip.hover title="Remover" v-if="row.index != items.length - 1" class="ml-1" pill variant="danger">
                  <i class="fa fa-minus"></i>
                </b-button>
              </template>
              <template v-slot:foot()="row">
                <div v-if="row.column == 'unitCost'">
                  TOTAL BRUTO
                </div>
                <div v-if="row.column == 'totalCost'">
                  {{ new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(allTotal) }}
                </div>
                <div v-else></div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5">
            <div v-if="insertionOrder.agencyPaymentDeadlineClient !== '' && insertionOrder.agencyPaymentDeadlineClient != null">
              <p class="mb-0"><strong>Prazo de Pagamento Agência</strong></p>
              <p>{{ insertionOrder.agencyPaymentDeadlineClient }}</p>
            </div>
            <div v-if="insertionOrder.revenuesClient !== '' && insertionOrder.revenuesClient != null">
              <p class="mb-0"><strong>Faturamento</strong></p>
              <p>{{ insertionOrder.revenuesClient }}</p>
            </div>
            <div v-if="insertionOrder.cancellation !== '' && insertionOrder.cancellation != null">
              <p class="mb-0"><strong>Cancelamento</strong></p>
              <p>{{ insertionOrder.cancellation }}</p>
            </div>
            <div v-if="insertionOrder.note1 !== '' && insertionOrder.note1 != null">
              <p class="mb-0"><strong>Observação 1</strong></p>
              <p>{{ insertionOrder.note1 }}</p>
            </div>
            <div v-if="insertionOrder.note1 !== '' && insertionOrder.note1 != null">
              <p class="mb-0"><strong>Observação 2</strong></p>
              <p>{{ insertionOrder.note2 }}</p>
              <p>{{ insertionOrder.emails }}</p>
            </div>
            <div v-if="insertionOrder.note1 !== '' && insertionOrder.note1 != null">
              <p class="mb-0"><strong>Observação 3</strong></p>
              <p>{{ insertionOrder.note3 }}</p>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="mt-4">
              <b-row style="text-align: -webkit-center;">
                <b-col cols="12">
                  <b-img :src="signatures.find(x => x.text === signature) !== undefined ? signatures.find(x => x.text === signature).base64 : ''" alt="Assinatura" class="w-250px" 
                    v-if="signatures.find(x => x.text === signature) !== undefined && signatures.find(x => x.text === signature).base64 !== ''">
                  </b-img>
                  <div v-else>
                    Sem Assinatura Cadastrada
                  </div>
                  <b-form-group class="mt-2" label="Escolha a Assinatura" style="font-weight: bold; font-size: 16px;">
                    <p style="font-size:x-small" ><b>Obrigatório</b></p>
                    <b-form-select class="d-block w-250px" name="signature" v-model="signature" :options="signatures"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>                
            </div>
          </b-col>
          <b-col cols="4">
            <table class="table-total">
              <tr class="table-line">
                <td class="table-title"><strong>Total Bruto</strong></td>
                <td class="table-value text-center">
                  <strong>
                    {{ new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(allTotal) }}
                  </strong>
                </td>
              </tr>
              <tr class="table-line">
                <td class="table-title"><strong>Comissão Agência</strong></td>
                <td class="table-value text-center">
                  <strong>
                    {{ (agencyCommision * 100) + ' %' }}
                  </strong>
                </td>
              </tr>
              <tr class="table-line">
                <td class="table-title"><strong>Total Líquido</strong></td>
                <td class="table-value text-center">
                  <strong>
                    {{ new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(allLiquidTotal) }}
                  </strong>
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Observação de Campanha" style="font-weight: bold; font-size: 16px;">
              <p style="font-size:x-small" ><b>Obrigatório</b></p>
              <b-form-input
                v-model="campaignNote"
                type="text"
                placeholder="Observação de Campanha"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Executivo" style="font-weight: bold; font-size: 16px;">
              <p style="font-size:x-small" ><b>Obrigatório</b></p>
              <b-form-input
                v-model="executive"
                type="text"
                placeholder="Executivo"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="w-100 mx-auto text-center mb-4">
              <b-button @click="submit" class="font-20px" variant="info" :disabled="isSubmitDisabled()">
                <i class="fa fa-floppy-o mr-1"></i>
                Salvar
              </b-button>
            </div>
          </b-col>
        </b-row>
        <template v-slot:overlay>
          <Spinner />
        </template>
      </b-overlay>
      <b-modal v-model="modal.show" title="Emails">
        <template>
          <div v-for="(email, index) in modal.emails" :key="`email_${index}`">
            <b-row>
              <b-col cols="9">
                <b-form-group label="Nome">
                  <b-form-input
                    v-model="modal.emails[index].name"
                    type="text"
                    placeholder="Nome"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Email">
                  <b-form-input
                    v-model="modal.emails[index].mail"
                    type="email"
                    placeholder="Email"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <div class="mt-55px">
                  <b-button variant="info" pill v-b-tooltip.hover title="Adicionar" @click="addEmailModal" :disabled="isEmailAddDisabled(index)">
                    <i class="fa fa-plus"></i>
                  </b-button>
                  <b-button variant="danger" pill v-b-tooltip.hover title="Remover" @click="removeEmailModal(index)" class="ml-1">
                    <i class="fa fa-minus"></i>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          
          <b-button variant="info" pill v-b-tooltip.hover title="Adicionar" @click="addEmailModal" v-if="modal.emails.length === 0">
            <i class="fa fa-plus"></i>
          </b-button>
        </template>
        <template #modal-footer>
          <b-button variant="info" @click="submitModal">
            Enviar
          </b-button>
          <b-button variant="danger" @click="cancelModal">
            Cancelar
          </b-button>
        </template>
      </b-modal>
    </template>
    <template v-else>
      <p class="text-center mt-5">
        Params Indefinidos
      </p>
    </template>
    <Spinner v-if="false" />
    <b-modal id="totalCost" title="Custo Total" hide-footer>
      <p class="my-4">Local destinado para identificar o valor contratado para a campanha.
      Inserir a previsão de investimento, e ao fechar o mês de veiculação o valor bruto da nota fiscal.
      </p>
    </b-modal>
    <b-modal id="campaignName" title="Campanha" hide-footer>
      <p class="my-4">Local utilizado para identificar a campanha. Essa informação é opcional, e ela é utilizada para facilitar e identificar da melhor forma o Pedido de Inserção com dados mais detalhados de sua entrega.</p>
    </b-modal>
    <b-modal id="negociationId" title="Tipo de compra" hide-footer>
      <p class="my-4">Local destinado para identificar o modelo de compra utilizado.
Essa informação é opcional, e ela é utilizada para facilitar e identificar os valores negociados na campanha.</p>
    </b-modal>
    <b-modal id="contractVolume" title="Volume contratado" hide-footer>
      <p class="my-4">Local destinado para identificar o volume de impressões e/ou views contratados.
Essa informação é opcional, e ela é utilizada para facilitar e identificar os valores negociados na campanha.
</p>
    </b-modal>
    <b-modal id="period" title="Período contratado" hide-footer>
      <p class="my-4">Local destinado para identificar o período contratado para a campanha.
Ela é utilizada para facilitar e identificar o período negociado para a campanha.
</p>
    </b-modal>
  </div>  
</template>

<script>
import { graphQLQuery } from '../services/GraphQLService'
import Spinner from '../components/Spinner'
import Swal from 'sweetalert2'
import moment from 'moment'

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  data() {
    return {
      datePickerItem: {startDate: '2020-12-01', endDate: '2020-12-31'},
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      fields: [
        { key: 'campaignName', label: 'Campanha' },
        { key: 'negociationId', label: 'Tipo de compra' },
        { key: 'contractVolume', label: 'Volume contratado' },
        { key: 'period', label: 'Período Contratado' },
        { key: 'totalCost', label: 'Custo Total' },
        { key: 'action', label: 'Ação' }
      ],
      allTotal: 0.00,
      allLiquidTotal: 0.00,
      agencyCommision: 0.00,
      items: [],
      rowEdit: null,
      negociationIdList: [
        { value: '', text: 'Selecione', form: '' }
      ],
      params: true,
      emails: [],
      observations: [],
      spinner: false,
      blocked: false,
      moneyFormatter: null,
      clientId: '',
      mediumId: '',
      token: '',
      piId: '',
      principalId: '',
      modal: {
        show: false,
        emails: []
      },
      signature: '',
      signatureUrl: '',
      signatureBase64: '',
      campaignNote: '',
      executive: '',
      signatures: [
        { value: '', text: 'Selecione', url: '' }
      ],
      insertionOrder: {
        id: null,
        mediumId: null,
        agencyCommision: null,
        liquidTotalValue: null,
        grossTotalValue: null,
        note1: null,
        note2: null,
        note3: null,
        emails: '',
        agencyPaymentDeadlineClient: '',
        cancellation: '',
        revenuesClient: '',
      },
      minDate: null,
      maxDate: null,
      issuacenDate: null
    }
  },
  components: {
    Spinner,
    DateRangePicker
  },
  methods: {
    saveRange (data) {
      data.clickApply()
    },
    formatRangeName (rangeName) {
      if (rangeName === 'Today') {
        return 'Hoje'
      } else if (rangeName === 'Yesterday') {
        return 'Ontem'
      } else if (rangeName === 'This month') {
        return 'Este mês'
      }
    },
    formatRangeArray () {
      return []
    },
    addItem () {
      this.updateTotal()
      
      this.items.push(
        { campaignName: '', channel: '', segmentation: '', format: '', device: '', dimension: '', negociationId: '', contractVolume: 1, period: this.datePickerItem, unitCost: 0, totalCost: 0 }
      )
    },
    removeItem (index) {
      this.rowEdit = null
      this.items.splice(index, 1)
    },
    editItem (index) {
      this.rowEdit = index
    },
    saveItem () {
      this.updateTotal()
      
      this.rowEdit = null
    },
    showModal () {
      this.modal.show = true
    },
    addEmailModal () {
      this.modal.emails.push({
        name: '',
        mail: ''
      })
    },
    removeEmailModal (index) {
      this.modal.emails = this.modal.emails.filter((emails, i) => i !== index)
    },
    isEmailAddDisabled (index) {
      return this.modal.emails[index].mail.trim() === '' || this.modal.emails[index].name.trim() === ''
    },
    isItemAddDisabled (index) {
      return this.validateItem(this.items[index]);
    },
    isItemEditDisabled (index) {
      return this.validateItem(this.items[index]);
    },
    isSubmitDisabled () {
      return this.rowEdit != null || this.signature == '' || this.signature == null || this.campaignNote == '' || this.campaignNote == null || this.items.length <= 1
    },
    submitModal () {
      this.$root.$emit('submitModal')

      this.spinner = true
      this.cancelModal()
      
      this.savePi(true)
    },
    cancelModal () {
      this.$root.$emit('cancelModal')
      this.modal.show = false
    },
    submit () {
      Swal.fire({
        title: 'Enviar email',
        text: "Deseja enviar o email após salvar a PI?",
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#17a2b8',
        confirmButtonText: 'Sim',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal()
        }
        else {
          this.savePi()
        }
      })
      //this.savePi()
    },
    updateTotal () {
      if(this.items.length > 0) {
        let bruteValue = this.items.map(x => {
          if(isNaN(x.totalCost)){
            let replaceString = x.totalCost.replace('R$ ', '').replace(',', '.')
            return parseFloat(replaceString)
          }
          else{
            return x.totalCost
          }
        }).reduce((prev, next) => prev + next)
        this.allTotal = parseFloat(bruteValue)
        this.allLiquidTotal = this.allTotal - (this.allTotal * this.agencyCommision)
      }
    },
    getMedium () {
      this.spinner = true
      let query = `
        {  
          channels: 
            getMediumDescription(id: "${this.mediumId}") 
            { 
              id 
              name 
              displayName 
              type
              hasPackageFee
              comissioned
              comissionValue
              enabled
              tagId
              clientsUsing {
                name
              }
              contacts {
                name
                mail
                phone    
              }    
              idExternalCrm 
              address { 
                zipCode 
                streetName 
                neighborhood 
                city 
                state 
                number 
              } 
              notePaymentTerm 
              noteRevenues
            }  
          }
      `

      graphQLQuery(this.$http, query, this.token)
        .then(data => data.data)
        .then(() => {
          this.spinner = false
        })
    },
    getClient () {
      this.spinner = true
      let query = `
        {
          serverDate
          clientParent (clientId: "${this.clientId}") {
            name
            entryDate
            exitDate
            birthDate
            hasFee
            negotiationType
          }
          client (id: "${this.clientId}") {
            id
            name
            logo
            address {
              zipCode
              streetName
              neighborhood
              number
              city
              state
              complement
            }
            observations
            emails
          }
        }
      `

      graphQLQuery(this.$http, query, this.token)
        .then(data => data.data)
        .then(response => {
          const client = response.data.client
          this.emails = client.emails
          this.observations = client.observations
          this.spinner = false
        })
    },
    getSignatures () {
      this.spinner = true
      let query = `
        {
          signaturesByClientId (clientId: "${this.clientId}") 
          { 
            id
            name 
            signature
            signatureBase64
          }
        }
      `

      graphQLQuery(this.$http, query, this.token)
        .then(data => data.data)
        .then(response => {
          const signatures = response.data.signaturesByClientId.map(x => {
            return {
              value: x.name,
              text: x.name,
              url: x.signature,
              base64: x.signatureBase64
            }
          })
          signatures.unshift({ value: '', text: 'Selecione', url: '', base64: '' })
          this.signatures = signatures
          this.spinner = false
        })
    },
    getPi () {
      this.spinner = true
      let query = `
        {
          currentInserionOrder(id: ${this.piId}) {
            id
            statusId
            clientId
            clientName
            fileName
            companyNameClient
            addressClient
            districtClient
            cityClient
            numberAddressClient
            principalInsertionOrderId
            zipCodeClient
            cNPJClient
            mediumId
            mediumName
            companyNameMedium
            addressMedium
            districtMedium
            cityMedium
            numberAddressMedium
            zipCodeMedium
            cNPJMedium
            createdIn
            updatedIn
            note1
            note2
            note3
            mails
            issuanceDate
            grossTotalValue
            agencyCommission
            liquidTotalValue
            signatureName
            signatureUrl
            agencyPaymentDeadlineClient
            cancellation
            revenuesClient
            campaignNote
            executive
            insertionOrderCampaigns{
              id
              insertionOrderId
              campaignName
              channel
              segmentation
              format
              device
              dimension
              negociationId
              contractVolume
              initialPeriod
              finalPeriod
              unitCost
              totalCost
              createdIn
            }
          }
        }
      `

      graphQLQuery(this.$http, query, this.token)
        .then(data => data.data)
        .then(response => {
          const currentInserionOrder = response.data.currentInserionOrder
          this.insertionOrder = {
            mediumId: currentInserionOrder.mediumId,
            agencyCommision: currentInserionOrder.agencyCommission,
            liquidTotalValue: currentInserionOrder.liquidTotalValue,
            grossTotalValue: currentInserionOrder.grossTotalValue,
            note3: currentInserionOrder.note3,
            note2: currentInserionOrder.note2,
            note1: currentInserionOrder.note1,
            emails: currentInserionOrder.mails,
            agencyPaymentDeadlineClient: currentInserionOrder.agencyPaymentDeadlineClient,
            cancellation: currentInserionOrder.cancellation,
            revenuesClient: currentInserionOrder.revenuesClient,
            campaignNote: currentInserionOrder.campaignNote
          }

          this.agencyCommision = currentInserionOrder.agencyCommission

          this.clientId = currentInserionOrder.clientId

          this.signature = currentInserionOrder.signatureName

          this.mediumId = currentInserionOrder.mediumId

          this.principalId = currentInserionOrder.principalInsertionOrderId
          
          const date = new Date(currentInserionOrder.issuanceDate)
          const newDate = new Date(date.getFullYear(), date.getMonth(), 1)
          this.issuacenDate = newDate
          this.minDate = new Date(date.getFullYear(), date.getMonth(), 1)
          this.maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
          this.datePickerItem = {startDate: moment(this.minDate).format('YYYY-MM-DD'), endDate: moment(this.minDate).format('YYYY-MM-DD')}

          this.campaignNote = currentInserionOrder.campaignNote
          this.executive = currentInserionOrder.executive
          
          this.updateTotal()

          if (currentInserionOrder.insertionOrderCampaigns.length === 0) {
            this.items = []
          } else {
            this.items = currentInserionOrder.insertionOrderCampaigns.map(x => {
              return {
                campaignName: x.campaignName, 
                channel: x.channel, 
                segmentation: x.segmentation, 
                format: x.format, 
                device: x.device, 
                dimension: x.dimension, 
                negociationId: x.negociationId, 
                contractVolume: x.contractVolume, 
                period: {'startDate': x.initialPeriod, 'endDate': x.finalPeriod}, 
                unitCost: x.unitCost, 
                totalCost: x.totalCost,
                signatureName: this.signature,
                signatureUrl: this.signatureUrl,
                campaignNote: this.campaignNote
              }
            })
          }
          this.addItem()

          this.spinner = false
          this.getSignatures()
          this.getMedium()
        })
    },
    getNegociation () {
      this.spinner = true
      let query = `
        {
          getSetup {
            negociations {
              id
              description
              form
            }
          }
        }
      `

      graphQLQuery(this.$http, query, this.token)
        .then(data => data.data)
        .then(response => {
          const negociations = response.data.getSetup.negociations.map(x => {
            return {
              value: x.id,
              text: x.description,
              form: x.form
            }
          })
          negociations.unshift({ value: '', text: 'Selecione', form: '' })
          this.negociationIdList = negociations
        })
    },
    savePi (sendMail) {      
      this.spinner = true
      let mutationsVar = {
        campaigns: this.items.filter((item, index) => {
          return this.items.length - 1 != index 
        }).map(x => {
          return {
            campaignName: x.campaignName, 
            channel: x.channel, 
            segmentation: x.segmentation, 
            format: x.format, 
            device: x.device, 
            dimension: x.dimension, 
            negociationId: x.negociationId === '' ? '14' : x.negociationId, 
            contractVolume: x.contractVolume, 
            initialPeriod: x.period.startDate, 
            finalPeriod: x.period.endDate, 
            unitCost: x.unitCost, 
            totalCost: x.totalCost,
            signatureName: this.signature,
            signatureUrl: this.signatures.find(x => x.text === this.signature).url,
            campaignNote: this.campaignNote,
            executive: this.executive
          }
        })
      }

      let mutation = `
        mutation updateInsertionOrderCampaings ($campaigns: [insertionOrderCampaignInput]!) {
          updateInsertionOrderCampaings(insertionOrderId:${this.piId}, campaings: $campaigns)
        }
      `

      graphQLQuery(this.$http, mutation, this.token, mutationsVar)
        .then((response) => {   
          if (response.status == 200) {
            if (sendMail === true) {
              this.saveMail(this.modal.emails)
                .then(() => {
                  Swal.fire({
                    title: 'Sucesso!',
                    text: 'O Pedido de Inserção foi salvo e o email foi enviado com sucesso',
                    icon: 'success',
                    confirmButtonText: 'OK'
                  })

                  this.spinner = false
                })
            } else {
              Swal.fire({
                title: 'Sucesso!',
                text: 'O Pedido de Inserção foi salvo com sucesso',
                icon: 'success',
                confirmButtonText: 'OK'
              })

              this.spinner = false
              this.blocked = true
            }   
          } else {
            Swal.fire({
              title: 'Erro',
              text: 'Erro Inesperado',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(() => {
          Swal.fire({
            title: 'Erro',
            text: 'Erro Inesperado',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
    },
    saveMail (emails) {   
      let mutationsVar = {
        mails: emails
      }

      let mutation = `
        mutation sendMailInsertionOrder ($mails: [insertionOrderMailInput]!){
          sendMailInsertionOrder(principalId: ${this.principalId}, insertionOrderId: ${this.piId}, insertionOrderMail: $mails)
        }
      `

      return graphQLQuery(this.$http, mutation, this.token, mutationsVar)
    },
    validateItem (item) {
      const validate = item.totalCost < 0
      return validate
    },
    formatDate (value) {
      
      const date = new Date(value)
      const newDate = new Date(this.minDate)
      if (date.getMonth() !== newDate.getMonth()) {
        return moment(newDate).format('DD/MM/YYYY')
      }
      return moment(value).format('DD/MM/YYYY')
    },
    formatPeriod (value) {
      return `${moment(value.startDate).format('DD/MM/YYYY')} - ${moment(value.endDate).format('DD/MM/YYYY')}`
    },
    formatUnitCost (row, unitCost) {
      if (unitCost === undefined || unitCost === '') {
        return this.moneyFormatter.format(0)
      }

      let negociation = this.negociationIdList.find(x => x.value === row.negociationId)

      if (negociation === undefined || negociation.form === '') {
        return this.moneyFormatter.format(0)
      }

      let result = ''

      if (negociation.form.includes('period')) {
        const startDate = new Date(row.period.startDate).getDate()
        const endDate = new Date(row.period.endDate).getDate()
        const period = endDate - startDate + 1
        result = negociation.form.replace('totalCost', row.totalCost).replace('contractVolume', row.contractVolume).replace('period', period)
      } else {
        result = negociation.form.replace('totalCost', row.totalCost).replace('contractVolume', row.contractVolume)
      }
      const evalResult = eval(result)

      if (isNaN(evalResult) || evalResult === Infinity) {
        return this.moneyFormatter.format(0)
      }
      
      return this.moneyFormatter.format(evalResult)
    }
  },
  mounted () {
    document.title = 'PI - Formulário';

    if(this.$route.query.token === undefined ||  this.$route.query.piId === undefined) {
      this.params = false
    }

    this.token = this.$route.query.token
    this.piId = this.$route.query.piId

    //history.pushState({}, null, '/form');

    this.moneyFormatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    moment.locale('pt-BR')

    this.updateTotal()
    this.getPi()
    this.getNegociation()
  }
}
</script>

<style scoped>
  .btn-info {
    background-color:#3fcbb6;
    border-color:#3fcbb6;
  }
  .btn-info:hover {
    background-color:#4bad9e;
    border-color:#4bad9e;
  }
  .btn-danger {
    background-color:#e1005d;
    border-color:#e1005d;
  }
  .btn-danger:hover {
    background-color:#cb0657;
    border-color:#cb0657;
  }
  .formPage-body {
    margin: 0px 15px;
  }

  .table-total {
    margin: 50px auto;
  }

  .table-line {
    border: 10px solid white;
  }

  .table-title {
    background: gray;
    padding: 10px 30px;
    text-align: center;
    color: white;
  }

  .table-value {
    padding: 10px 100px;
  }

  .font-20px {
    font-size: 20px;
  }

  .mt-55px {
    margin-top: 55px;
  }

  .min-heigth-50vh {
    min-height: 50vh;
  }

  .w-250px {
    width: 250px !important;
  }
  
  .calendars-container {
    margin: 0 auto;
  }

  div.drp-calendar.col.left.single {
    border: none !important;
  }

  tbody > tr {
    max-height: 60px;
  }
</style>