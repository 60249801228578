<template>
  <div>
    <template v-if="params">
      <b-overlay :show="spinner" rounded="sm" variant="transparent">
        <div class="modal-box p-4">
          <h2 class="text-center">Deseja aceitar o Pedido de Inserção de Número: {{ principalId }}?</h2>
          <b-row class="my-4">
            <b-col cols="12">
              <label for=""><strong>Mídia</strong></label>
              <p>
                {{ mediumName }}
              </p>

              <label for=""><strong>Cliente</strong></label>
              <p>
                {{ clientName }}
              </p>

              <label for=""><strong>PDF</strong></label>
              <b-button class="d-block" @click="downloadPdf(true)">
                <i class="fa fa-download"></i> Download
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="pdfUrl != ''">
            <b-col cols="12">
              <iframe :src="pdfUrl" style="width:100%; height:500px;" frameborder="0"></iframe>
            </b-col>
          </b-row>
          <div class="d-table mx-auto">
            <b-button @click="recuse" variant="danger">
              Reprovar
            </b-button>
            <b-button @click="accept" variant="info" class="ml-3">
              Aprovar
            </b-button>
          </div>
        </div>
        <template v-slot:overlay>
          <Spinner />
        </template>
      </b-overlay>
    </template>
    <template v-else>
      <p class="text-center mt-4">
        Está PI já foi aceita ou recusada.
      </p>
    </template>  
  </div>
</template>

<script>
import Spinner from '../components/Spinner'
import Swal from 'sweetalert2'
import FormService from '@/services/FormService'
import { graphQLQuery } from '../services/GraphQLService'

export default {
  data() {
    return {
      params: true,
      key: null,
      token: null,
      principalId: '',
      piId: '',
      service: null,
      insertionOrder: null,
      spinner: false,
      pdfUrl: '',
      clientName: '',
      mediumName: ''
    }
  },
  components: {
    Spinner
  },
  methods: {
    downloadPdf (isDownload) {
      this.spinner = true
      this.service.download(this.piId, this.token)
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        this.pdfUrl = url;
        if (isDownload) {
          a.href = url;
          a.download = `Pi-${this.piId}`;
          document.body.appendChild(a);
          a.click();    
          a.remove();
        }
        this.spinner = false
      })
    },
    getInfo () {
      this.spinner = true
      this.service.get(this.token, this.key)
        .then(res => {
          return res.data
        })
        .then(response => {
          console.log(response)
          if (response.id == 0) {
            this.piId = response.data.insertionOrderId
            this.getPi()
          }
          else {
            Swal.fire({
              icon: 'info',
              title: 'Atenção',
              html: response.data,
              allowOutsideClick: false
            })
            this.params = false
          }
        })
        .catch(() => {
          Swal.fire({
            icon: 'info',
            title: 'Atenção',
            html: 'Acesso não autorizado. <br> Entre em contato com a Blinks Essence',
            allowOutsideClick: false
          })
          this.params = false
        })
    },
    getPi () {
      this.spinner = true
      let query = `
        {
          currentInserionOrder(id: ${this.piId}) {
            id
            principalInsertionOrderId
            statusId
            clientId
            clientName
            fileName
            companyNameClient
            addressClient
            districtClient
            cityClient
            numberAddressClient
            zipCodeClient
            cNPJClient
            mediumId
            mediumName
            companyNameMedium
            addressMedium
            districtMedium
            cityMedium
            numberAddressMedium
            zipCodeMedium
            cNPJMedium
            createdIn
            updatedIn
            note1
            note2
            note3
            mails
            issuanceDate
            grossTotalValue
            agencyCommission
            liquidTotalValue
            signatureName
            signatureUrl
            insertionOrderCampaigns{
              id
              insertionOrderId
              campaignName
              channel
              segmentation
              format
              device
              dimension
              negociationId
              contractVolume
              initialPeriod
              finalPeriod
              unitCost
              totalCost
              createdIn
            }
          }
        }
      `

      graphQLQuery(this.$http, query, this.token)
        .then(data => data.data)
        .then(response => {
          const currentInserionOrder = response.data.currentInserionOrder
          this.principalId = currentInserionOrder.principalInsertionOrderId
          this.clientName = currentInserionOrder.clientName
          this.mediumName = currentInserionOrder.mediumName
          this.downloadPdf(false)
        })
    },
    accept () {
      this.service.action(this.token, this.key, 1)
        .then(response => {
          console.log(response)
          Swal.fire({
            title: 'Aprovado!',
            text: 'Você aprovou o Pedido de Inserção',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            this.params = false
          })
        })
    },
    recuse () {
      Swal.fire({
        title: 'Digite o motivo da recusa do Pedido de Inserção',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        preConfirm: (message) => {
          if (message) {
            this.spinner = true
            this.service.action(this.token, this.key, 0, message)
              .then(response => {
                console.log(response)
                Swal.fire({
                  title: 'Recusado!',
                  text: 'Você recusou o Pedido de Inserção',
                  icon: 'info',
                  confirmButtonText: 'OK'
                }).then(() => {
                  this.params = false
                })
                this.spinner = false
              })
          }
          else {
            Swal.showValidationMessage(`O campo deve ser preenchido`)
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
    }
  },
  mounted () {
    if(this.$route.query.token === undefined || this.$route.query.user === undefined) {
      this.params = false
    }
    this.token = this.$route.query.token
    this.key = this.$route.query.user

    history.pushState({}, null, '/#/question');

    this.service = new FormService(this.$http)

    this.getInfo()
  }
}
</script>

<style>
  .modal-box {
    box-shadow: 0 0 5px grey;
    width: 75%;
    margin: 130px auto;
  }
</style>